import axios from "./axios";


export async function getProductCategoryById(setState:any,setState1:any, id:any){  

  try {
        
      const response = await axios.get(`products/getOne/${id}`);
     
    await  setState(response?.data[0])
    await  setState1({...response?.data[0], quantity: 1})
  
  
    } catch (error) {
      console.error(error as Error)
    }
  }


export async function getPromo(){  

  try {
        
      const response = await axios.get(`users/getPromo`);
     
    return response?.data
  
  
    } catch (error) {
      console.error(error as Error)
    }
  }

  export async function AddOrders(data:any){  

    try {
          
      await axios.post(`orders/addOrders`, data)
    
      } catch (error) {
        console.error(error as Error)
      }
    }

    export async function LoginAuth(obj: any, setAuth: (e: any) => void) {
    
      try {
        const response = await axios.post(`auth/login`, obj);
        
        
        setAuth(response.data);
      } catch (error) {
      }
    }




  export async function editItem(obj:any){  
    try {
        
      await axios.put(`products/editProducts/${obj.id}`,obj);
     
 
  
    } catch (error) {
      console.error(error as Error)
    }
  }
  export async function deleteProduct(id:any){  
    try {
        
      await axios.delete(`products/deleteProducts/${id}`);
     
  
  
    } catch (error) {
      console.error(error as Error)
    }
  }
  export async function addProducts(obj:any){  
    try {
          await axios.post(`products/addProducts/`,obj);
     
    
  
    } catch (error) {
      console.error(error as Error)
    }
  }

  export async function getProductCategory(setState:any){  
    try {
        
      const response = await axios.get(`products/fullProducts`);
     
    await  setState(response?.data)
  
  
    } catch (error) {
      console.error(error as Error)
    }
  }

  export async function uploadImageHandleradd(e:any,setImg:any) {

  
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    if (formData.has("image")) {
      try {
        const response = await axios.post(`products/addPicture`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
     
        setImg(response?.data?.dirname);
        
            
      } catch (error) {
        return "Server request is failed";
      
    }
  }
}

export async function getOrders(setState:any){  
  try {
      
    const response= await axios.get(`orders/fullOrders`).then((res:any)=>res.data)
   
  await  setState(response)


  } catch (error) {
    console.error(error as Error)
  }
}
