import React from 'react';
import {  Routes, Route } from 'react-router-dom';
import HomePages from './pages/HomePages';
import ProductCard from './pages/ProductPages';
import AboutPages from './pages/AboutPages';
import Policy from './pages/Privacy';
import Cart from './pages/Cart';
import Orders from './pages/Orders';
import CashSucces from './pages/CashSuccses';
import Payment from './pages/Payment';
import Login from './pages/Login';
import Adminpage from './pages/Admin';
import AdminProduct from './pages/AdminProduct';
import AdminOrders from './pages/AdminOrders';
import AdminPromo from "./pages/AdminPromo"
import Clientorder from './pages/ClientOrder';
import Paysucsses from './pages/PaymentSucces';


function App() {

  return (
    <div className=" md:w-full">

      <Routes>
        <Route path='/' element={<HomePages/>}/>
        <Route path={'/product/:id'} element={<ProductCard />}/>
        <Route path={'/About'} element={<AboutPages />}/>
        <Route path={'/Privacy'} element={<Policy />}/>
        <Route path={'/Cart'} element={<Cart/>}/>
        <Route path={'/Orders'} element={<Orders/>}/>
        <Route path={'/Cashsucces'} element={<CashSucces/>}/>
        <Route path={'/Payment'} element={<Payment/>}/>

        <Route path={'/paysucsses'} element={<Paysucsses/>}/>
        <Route path={'/Login'} element={<Login/>}/>
        <Route path={'/Admin'} element={<Adminpage/>}/>
        <Route path={'/Admin/Adminproduct'} element={<AdminProduct/>}/>
        <Route path={'/Admin/AdminOrders'} element={<AdminOrders/>}/>
        <Route path={'/Admin/AdminPromo'} element={<AdminPromo/>}/>
        <Route path={'/CustmerOrder'} element={<Clientorder/>}/>
      </Routes>
    </div>
  );
}

export default App;

