import React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";


interface Product {
    id: string;
    name: string;
    images: string[];
    width: number;
    height: number;
    price: number;
    brand: string;
}

interface ProductCardProps {
    data: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ data }) => {
    const router = useNavigate();

    const handleClick = () => {
        router(`/product/${data.id}`);
    };

    return (
        <div onClick={handleClick} className="col-span-1 cursor-pointer border-[1.2px] border-slate-300 bg-slate-200 rounded-sm p-2 transition hover:scale-105 text-center text-sm">
            <div className="flex flex-col items-center w-full gap-1">
                <div className="aspect-square overflow-hidden relative w-full">
                    {data?.images[0] ?<img
                        src={data?.images[0]}
                        alt={data?.name}
                        className="w-full h-full object-contain"
                    />:<Loading/>}
                </div>
                <div className="mt-4 text-[10px] md:text-xl">{data.name}</div>
                <div className="text-[9px] text-gray-700 md:text-xl">Размер: {data.width}см*{data.height}см</div>
                <div>Цена: <span className="font-semibold">{data.price}</span> ₽/ {data.brand}</div>
            </div>
        </div>
    );
};

export default ProductCard;
