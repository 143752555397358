
import axios from "../../axios";
import Container from "../../components/Container";
import FormWrap from "../../components/FormWrap";
import { useEffect, useState } from "react";





const OrderForm = ({res}:any) => {



  
    const [admin, setAdmin]=useState<any>(null)
    const [value, setValue]=useState<any>(
        res.reverse()
    )
    const [value1, setValue1]=useState(true)
    const [searchorder, setSearchorder]=useState<any>()
    const [value2, setValue2]=useState(true)
    const [value3, setValue3]=useState(true)
    const [searching, setSearching]=useState(false)
    const [searchItem, setSearchItem]=useState<any>('')
    const [ready, setReady]=useState({
        all:false,
        ready:false,
        completed:false,
        search:false
    })
    useEffect(()=>{
        const cartItem:any=localStorage.getItem('admin');
        const cProducts:any=JSON.parse(cartItem)
        setAdmin(cProducts)
    },[])
    // admini kaxvacutyunic cikl e @nknum

    useEffect(() => {
        getData()
      }, []);

    useEffect(() => {
        getData()
      }, [value1]);

    useEffect(() => {
        getData2()
      }, [value3]);

      useEffect(() => {
        getData1()
      }, [value2]);

    async function getData(){
     
        const response= await axios.get(`orders/fullOrders`).then((res)=>res.data)
    
        setValue(response.filter((item:any)=>item.ready===false && item.completed===false))
        setSearching(false)
      }
      async function getData1(){
     
        const response= await axios.get(`orders/fullOrders`).then((res)=>res.data)
        setValue(response.filter((item:any)=>item.ready===true && item.completed===false))
        setSearching(false)
      }
      async function getData2(){
    
        const response= await axios.get(`orders/fullOrders`).then((res)=>res.data)
        setValue(response.filter((item:any)=>item.ready===true && item.completed===true))
        setSearching(false)
      }

    async function OrderReady(e:any, data:any){
        e.preventDefault();
        const data1={
          ...data,
          ready:true
        }
       await axios.put(`orders/editOrders/${data1.id}`, data1)
     setValue1(!value1)
    }

   
    async function OrderCompleted(e:any, data:any){
        e.preventDefault();
        const data1={
          ...data,
          completed:true
        }
        
       await axios.put(`orders/editOrders/${data.id}`, {
        ...data,
        completed:true
      })
       await axios.post(`payment/getPayment/${data.order_id}`)
        
        setValue2(!value2)
    }

    async function Delete(e:any, data:any){
        e.preventDefault();
        await axios.post(`payment/clearPayment/${data.order_id}`)    
        await axios.delete(`orders/deleteOrders/${data.order_id}`) 
        setValue1(!value1)
      }
      async function Delete1(e:any, data:any){
        e.preventDefault();
        await axios.post(`payment/clearPayment/${data.order_id}`)    
        await axios.delete(`orders/deleteOrders/${data.order_id}`)  
        setValue2(!value2)
        
      }
      async function Delete2(e:any, data:any){
        e.preventDefault();
        await axios.delete(`orders/deleteOrders/${data.order_id}`)  
        setValue3(!value3)
        
      }
   async function search(){
    if(searchItem.length>3){
     const res =await axios.get(`orders/getOneOrder/${searchItem}`)   
    setSearchorder(res.data)

    }
    setSearching(true)
    return searchorder
   }
   
   
    return ( <>
       <Container>
        {admin && <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-2 mt-5">
       <div onClick={()=>{setValue1(!value1);   setReady({all:true,ready:false,completed:false, search:false})}} className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px]">Не Подтвержденные Заказы</div>
          <div onClick={()=>{setValue2(!value2);setReady({all:false,ready:true,completed:false, search:false})}} className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px]">Подтвержденные Заказы</div>
          <div onClick={()=>{setValue3(!value3);setReady({all:false,ready:false,completed:true, search:false})}} className="col-span-1 cursor-pointer border-[1.4px]
         border-slate-300 bg-red-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px]">Завершенные Заказы</div>
        <div>
           <input className="col-span-1 cursor-pointer border-[1.4px] border-slate-300 text-white rounded-sm p-2 transition hover:scale-105 text-center items-center text-[10px] mb-2 md:text-[15px] mr-3" type="search" value={searchItem} onChange={(e:any)=>setSearchItem(e.target.value)}/>
          <button onClick={()=>search()}>Поиск</button>
          </div>
        </div>}
        </Container>

        <>{ !searching &&
      <div className='grid grid-cols-1  mt-10 ml-5  sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8'>{value?.map((data:any, i:number)=>{
            return <div key={i} className="col-span-1 cursor-pointer border-[1.2px] border-slate-300 bg-slate-200  rounded-sm p-2 transition hover:scale-105 text-center text-sm" >
                <Container  key={i}>
                <FormWrap  key={i} >
                <h4 className="  flex"><p className="font-semibold  mr-2 text-[14px] w-full">Номер Заказа: </p><span className="text-[12px]">{data.order_id}</span></h4>
                <div >
       
            
                {data.custmer?.map((item:any, i:any)=>{
                return <div className="mt-4" key={i}>
                    <div className="text-[13px] flex mt-1"><p className="font-semibold mr-1">Фамилия Заказчика: </p><span className="text-[13px]">{item.name}</span></div>
                    <div className="text-[13px] flex" mt-1><p className="font-semibold mr-1">Имя Заказчика: </p><span className="text-[13px]">{item.firstname}</span></div>
                    <div className="text-[13px] flex mt-1"><p className="font-semibold mr-1">Номер Телефона: </p><span className="text-[13px]">{item.phone}</span></div>
                    {data.deliveri && < >
                <h4 className="text-[15px] mx-auto m-2 font-semibold">Адресс Доставки</h4>
                <div className="text-[15px] flex"><p className="font-semibold mr-1">город: </p><span className="text-[13px]">{item.city}</span></div>
                <div className="text-[15px] flex"><p className="font-semibold mr-1">ул. или район: </p><span className="text-[13px]">{item.strit}</span></div>
                <div className="text-[15px] flex"><p className="font-semibold mr-1">дом и кв.: </p><span className="text-[13px]">{item.home}</span></div>

                </>}

                </div>
            })}
        
       <div className="grid grid-cols-6 text-xs gap-4 pb-2 items-center mt-5">

       <div className="col-span-2 justify-self-start">Товар</div>
       <div className="justify-self-center">Размер</div>
       <div className="justify-self-center">Кол</div>
       <div className="justify-self-center">Цена</div>

</div>
                {data.product?.map((item:any, i:any)=>{
                return <div key={i} className="grid grid-cols-6 text-xs gap-4 pb-2 items-center mb-5 mt-3" >
                    <div className="col-span-2 text-[10px] justify-self-start">{item.prodname}</div>
                    <div className=" justify-self-center">{item.prodsize}</div>
                    <div className="justify-self-center">{item.prodquantity}{item.prodweight}</div>
                    <div className="justify-self-end">{item.prodprice}₽</div>

                </div>
            })}
               <h4 className="text-[15px] flex"><p className="font-semibold mr-2">Итог за Заказ: </p><span className="text-[14px]">{data.price}₽</span></h4>

               {data.checkout=== "online"? <div className="mt-10">
                    {data.status==="waiting_for_capture" || data.status === "succeeded" ?<div>Заказ оплачен</div>:<div>Проблемы с оплатой нужно связяаться с клиентом</div>}
                    </div>:<div className="col-span-2 justify-self-start">
                    <div className="font-semibold mr-1">Заказчика выбрал наличный метод платежа 
                    прежде чем подтвердить заказ нужно связяаться с клиентом </div>
                        </div>}

            </div>
            <div className="flex w-full">
            {ready.all===true && <><button  className="w-[40%] mt-5 disabled:opacity-70 disabled:cursor-not-allowed rounded-md hover:opacity-80 transition border-s-slate-700
    flex items-center mx-auto justify-center gap-2 bg-red-500 py-1 px-1 text-[12px] " onClick={(e)=>Delete(e, data)} >Удалить заказ</button>
             <button className=" w-[40%] mt-5 disabled:opacity-70 disabled:cursor-not-allowed rounded-md hover:opacity-80 transition border-s-slate-700
    flex items-center mx-auto justify-center gap-2 bg-slate-500 py-1 px-2 text-[12px]" onClick={(e)=>OrderReady(e, data)} >Подтвердить заказ</button></>}
    {ready.ready===true && <><button  className="w-[40%] mt-5 disabled:opacity-70 disabled:cursor-not-allowed rounded-md hover:opacity-80 transition border-s-slate-700
    flex items-center mx-auto justify-center gap-2 bg-red-500 py-1 px-1 text-[12px] " onClick={(e)=>Delete1(e, data)} >Удалить заказ</button><button className=" w-[40%] mt-5 disabled:opacity-70 disabled:cursor-not-allowed rounded-md hover:opacity-80 transition border-s-slate-700
    flex items-center mx-auto justify-center gap-2 bg-slate-500 py-1 px-2 text-[12px]" onClick={(e)=>OrderCompleted(e, data)} >Завершить заказ</button></>}
       {ready.completed===true && <><button  className="w-[40%] mt-5 disabled:opacity-70 disabled:cursor-not-allowed rounded-md hover:opacity-80 transition border-s-slate-700
    flex items-center mx-auto justify-center gap-2 bg-red-500 py-1 px-1 text-[12px] " onClick={(e)=>Delete2(e, data)} >Удалить заказ</button></>}
            </div>
              
                    </FormWrap>
                </Container>


               
         </div>
        })}
       
       
       </div>
}</>
       {searching && <div className='grid grid-cols-1  mt-10 ml-5  sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-8'>{searchorder?.map((data:any, i:number)=>{
            return <div key={i} className="col-span-1 cursor-pointer border-[1.2px] border-slate-300 bg-slate-200  rounded-sm p-2 transition hover:scale-105 text-center text-sm" >
                <Container  key={i}>
                <FormWrap  key={i} >
                <h4 className="text-[15px] flex"><p className="font-semibold mr-2">Номер Заказа: </p><span className="text-[13px]">{data.order_id}</span></h4>
                {data.custmer?.map((item:any, i:any)=>{
                return <div className="mt-4" key={i}>
                    <div className="text-[13px] flex"><p className="font-semibold mr-1">Фамилия Заказчика: </p><span className="text-[13px]">{item.name}</span></div>
                    <div className="text-[13px] flex"><p className="font-semibold mr-1">Имя Заказчика: </p><span className="text-[13px]">{item.firstname}</span></div>
                    <div className="text-[13px] flex"><p className="font-semibold mr-1">Номер Телефона: </p><span className="text-[13px]">{item.phone}</span></div>
                    {data.deliveri && < >
                <h4 className="text-[15px] mx-auto m-2 font-semibold">Адресс Доставки</h4>
                <div className="text-[15px] flex"><p className="font-semibold mr-1">город: </p><span className="text-[13px]">{item.city}</span></div>
                <div className="text-[15px] flex"><p className="font-semibold mr-1">ул. или район: </p><span className="text-[13px]">{item.strit}</span></div>
                <div className="text-[15px] flex"><p className="font-semibold mr-1">дом и кв.: </p><span className="text-[13px]">{item.home}</span></div>

                </>}

                </div>
            })}
        
       <div className="grid grid-cols-6 text-xs gap-4 pb-2 items-center mt-5">

       <div className="col-span-2 justify-self-start">Товар</div>
       <div className="justify-self-center">Размер</div>
       <div className="justify-self-center">Кол</div>
       <div className="justify-self-center">Цена</div>

</div>
                {data.product?.map((item:any, i:any)=>{
                return <div key={i} className="grid grid-cols-6 text-xs gap-4 pb-2 items-center mb-5 mt-3" >
                    <div className="col-span-2 justify-self-start">{item.prodname}</div>
                    <div className=" justify-self-center">{item.prodsize}</div>
                    <div className="justify-self-center">{item.prodquantity}{item.prodweight}</div>
                    <div className="justify-self-end">{item.prodprice}₽</div>

                </div>
            })}
               <h4 className="text-[15px] flex"><p className="font-semibold mr-2">Итог за Заказ: </p><span className="text-[10px]">{data.price}₽</span></h4>
                {data.checkout=== "online"? <div className="mt-10">
                    {data.status==="waiting_for_capture" || data.status === "succeeded" ?<div>Заказ оплачен</div>:<div>Проблемы с оплатой нужно связяаться с клиентом</div>}
                    </div>:<div className="col-span-2 justify-self-start">
                    <div className="font-semibold mr-1">Заказчика выбрал наличный метод платежа 
                    прежде чем подтвердить заказ нужно связяаться с клиентом </div>
                        </div>}
     
              
                    </FormWrap>
                </Container>


               
         </div>
        })}
       
       
       </div>}
    </> );
}
 
export default OrderForm;